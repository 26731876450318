import React from 'react'
import { Home } from '../Views'
import SEO from '../components/seo'

function HomePage(props) {
    return (
        <>
            <SEO title="Home" />
            <Home {...props} />
        </>
    )
}

export default HomePage
